import { render, staticRenderFns } from "./companyNews.vue?vue&type=template&id=27d7c206&scoped=true&"
import script from "./companyNews.vue?vue&type=script&lang=js&"
export * from "./companyNews.vue?vue&type=script&lang=js&"
import style0 from "./companyNews.vue?vue&type=style&index=0&id=27d7c206&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d7c206",
  null
  
)

export default component.exports