<template>
  <div id="companyNewsComp">
    <header-comp></header-comp>
    <section class="topBg">
      <h3>公司动态</h3>
      <p>查看关于公司及业内最新资讯动态</p>
      <img :src="isMobile?urlMobile:url" alt="关于我们" />
    </section>
    <section class="gsdtContent" id="gsdt">
      <h3></h3>
      <div class="imgBox">
        <div class="block" v-for="(item) in companyNewsList" :key="item.id">
          <img :src="item.coverUrl" :alt="item.title">
          <div class="content">
            <div class="title" :title="item.title">{{ item.title }}</div>
            <div class="detail">
              <span>{{item.createTime.split(' ')[0]}} 发布</span>
              <span class="more" @click="goMore(item.id)"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.pageNo" :page-size="queryParams.pageSize" :layout="isMobile?'prev, pager, next':'total, prev, pager, next'"
          :total="total">
        </el-pagination>
      </div>
    </section>
    <footer-comp></footer-comp>
  </div>
</template>

<script type="text/javascript">
import headerComp from '@/views/components/headerComp'
import footerComp from '@/views/components/footerComp'
import { getCompanyNewsList } from '@/api/index'
export default {
  name: "companyNewsComp",
  components: {
    headerComp, footerComp
  },
  data() {
    return {
      url: require('../assets/images/companyNewBg.png'),
      urlMobile: require('../assets/images/mobile/dongtai_dongtai_normal.png'),
      url1: require('../assets/images/companyNewImg.png'),
      queryParams: {
        enable: '1',
        pageNo: 1,
        pageSize: 12
      },
      companyNewsList: [],
      total: null
    }
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  methods: {
    goMore(id) {
      this.$router.push('/companyNewsDetail?id=' + id)
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.queryParams.pageNo = val
      this.getData()
    },
    getData() {
      getCompanyNewsList(this.queryParams).then(res => {
        this.companyNewsList = res.data.records
        this.total = res.data.total
      }).catch(e => {
        console.log(`output->e`, e)
      })
    }
  },
  created() {
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import '../assets/css/style.default.css';
@import '../assets/css/custom.css';

#companyNewsComp {
  position: relative;
  width: 100%;
}

@media (min-width:992px) {
  #companyNewsComp {
    position: relative;
    padding-top: 182px;
    width: 100%;
  }
}
.topBg {
  width: 100%;
  height: 240px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.contactUs {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 65% 100%;
  }

  @media (min-width:992px) {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3,
  p {
    position: absolute;
    left: 50px;
  }

  h3 {
    font-size: 32px;
    top: 82px;
  }

  p {
    font-size: 16px;
    top: 138px;
  }

  @media (min-width:992px) {

    h3,
    p {
      position: absolute;
      left: 360px;
    }

    h3 {
      font-size: 32px;
      top: 82px;
    }

    p {
      font-size: 16px;
      top: 138px;
    }
  }
}
.gsdtContent {
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  h3 {
    text-align: center;
    margin: 80px auto 40px;
    width: 126px;
    height: 40px;
    background: url('../assets/images/companyNewstitle.png') no-repeat;
    background-size: 100% 100%;
  }

  .imgBox {
    flex-wrap: wrap;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 384px);
    box-sizing: border-box;
    width:100%;
    .block {
      width: calc(100% - 16px);
      height: 366px;
      box-shadow: 0px 0px 8px 0px rgba(20, 19, 144, 0.24);
      margin:0 auto 24px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 216px;
        object-fit: cover;
      }

      .content {
        width: 100%;
        height: 150px;
        padding: 24px;
        box-sizing: border-box;

        .title {
          font-size: 18px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .detail {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;

          span {
            color: #999999;
            font-size: 14px;
          }

          .more {
            width: 100px;
            height: 34px;
            background: url('../assets/images/more.png') no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (min-width:992px) {
    .imgBox {
      flex-wrap: wrap;
      justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(auto-fill, 384px);
    }
  }

  .page {
    padding: 20px;
    box-sizing: border-box;
    text-align: center;

    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {}

    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #262626;
      ;
    }

    .el-pagination.is-background .el-pager li {
      margin: 0 5px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #DCDEE0;
    }
  }
}
</style>